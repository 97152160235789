import 'react-native-gesture-handler';
import { AppRegistry, Platform } from 'react-native';

// import { registerRootComponent } from 'expo';

import App from '@pennygem/shared/App';

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately
// registerRootComponent(App);

AppRegistry.registerComponent('main', () => App);

if (Platform.OS === 'web') {
    AppRegistry.runApplication('main', {
        rootTag: document.getElementById('root'),
    });
}
